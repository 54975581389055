moya = moya || {};

(function (moya) {
	/**
	 * @typedef {Object} MoyaToggleConfig
	 * @property {number} closingDelay
	 * @property {number} openingDelay
	 * @property {boolean} closeOnBodyClick
	 * @property {boolean} closeOnEsc
	 * @property {string} openingClassPostfix
	 * @property {string} closingClassPostfix
	 * @property {boolean} closeOthers
	 * @property {function} onOpen
	 * @property {function} onClose
	 * @private
	 */


	moya.toggle = {
		/**
		 * @param {string} toggleElementName
		 * @param {string} openElementName
		 * @param {string} toggleKey
		 * @param {Function} [onOpen]
		 * @param {Function} [onClose]
		 * @param {Object | MoyaToggleConfig} [options]
		 * @returns {Toggle | null}
		 */
		addFromSelectors: function (toggleElementName, openElementName, toggleKey, onOpen, onClose, options) {
			return moya.toggle.add(
				document.querySelector(toggleElementName),
				document.querySelector(openElementName),
				toggleKey,
				onOpen,
				onClose,
				options,
			);
		},
		/**
		 * @param {HTMLElement} toggleElement
		 * @param {HTMLElement} openElement
		 * @param {string} toggleKey
		 * @param {Function} [onOpen]
		 * @param {Function} [onClose]
		 * @param {Object | MoyaToggleConfig} [options]
		 * @returns {Toggle | null}
		 */
		add: function (toggleElement, openElement, toggleKey, onOpen, onClose, options) {
			if (!toggleElement || !openElement || !toggleKey) {
				return null;
			}

			options = options || {}
			options.onClose = onClose;
			options.onOpen = onOpen;
			return MoyaContainer.get('core.toggle').create(toggleElement, openElement, toggleKey, options);
		},
	};
})(moya);
