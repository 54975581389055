(function($) {
	$.fn.formBuilder = function(options) {
		var o= $.extend({}, $.fn.formBuilder.defaults, options);
		var $form = $('#formbuilder .design_form');
		var $paletteItems = $('#formbuilder .palette .item');
		var $dialog, $dialogConfirm, $dialogSettings;
		var isModified = false;
		var needToConfirm = false;
		window.onbeforeunload = confirmExit;
		function confirmExit() {
			if (needToConfirm) {
				return o.messageDesignIsModified;
			}
		}

		$('.palette .ui-widget-header').click(function () {
			$(this).next().slideToggle('fast');
		});

		$('.save_design').live('click', function () {
			needToConfirm = false;
			$form.submit();
		});
		$('.cancel_design').live('click', function () {
			needToConfirm = false;
		});

		$('.preview_button').button({
			'icons' : {
				'primary' : 'preview_icon_edit'
			}
		}).live('click', function () {
			var $button = $(this);
			if($button.button( "option", "icons" ).primary == 'preview_icon_edit') {
				// Load the preview with ajax
				$button.button("option", "icons", {primary:'preview_icon_loading'});
				$button.button("option", "disabled", true);
				$.get(o.urlPreview, function (data) {
					$('.wrapper').hide();
					$('.preview').html(data).show().effect("highlight", {}, 500);
					$button.button("option", "label", o.messagePreviewChanges);
					$button.button("option", "icons", {primary:'preview_icon_preview'});
					$button.button("option", "disabled", false);
				});
			}
			else {
				$('.preview').hide();
				$('.wrapper').show().effect("highlight", {}, 500);
				$button.button("option", "label", o.messagePreview);
				$button.button("option", "icons", {primary:'preview_icon_edit'} );
			}
		});

		$("div.palette_header").click(function(){
			$("div.palette").slideToggle('fast');
			if ($(this).hasClass('open')) {
				$(this).toggleClass('open closed');
			}
			else {
				$(this).toggleClass('closed open');
			}
		});

		// Setup the items
		return this.each(function () {
			var $this = $(this);
			_setup();

			/************************
			 * SETUP FUNCTIONS
			 ***********************/

			function _setup()
			{
				_setupItems();
				_setupSortable();
				_setupPalette();
				_setupSettings();
				_setupDialogs();
			}

			// Setup the form elements
			function _setupItems()
			{
				$this.find('.elm').each(function () {
					_setupItem($(this));
				});
			}

			function _setupItem($item)
			{
				$item.data('name', $item.attr('id').replace('elm_', ''));
				_setupItemControls($item);

				if(isEditableItem($item)) {
					_setupItemLabel($item);
				}
			}

			// Add control buttons such as 'edit and 'remove' to each form element
			function _setupItemControls($item)
			{
				var $controls = $('<div></div>');
				$controls.attr('class', 'controls');

				// Create edit control
				var $edit = $('<a></a>')
					.attr('href', 'javascript:void(0);')
					.attr('class', 'controls_edit controls_action')
					.html(o.messageEditItem)
					.bind('click', function () {
						editItem($item);
					});

				// Create remove control
				var $remove = $('<a></a>')
					.attr('href', 'javascript:void(0);')
					.attr('class', 'controls_remove controls_action')
					.html(o.messageRemoveItem)
					.bind('click', function () {
						removeItem($item);
					});

				if(isEditableItem($item)) {
					$controls.append($edit).append(' | ').append($remove);
				}
				else {
					$controls.append($remove);
				}

				// Add the controls
				$item.append($controls);

				// Hover on item
				$item.bind('mouseover', function () {
					$controls.show();
				});
				$item.bind('mouseout', function () {
					$controls.hide();
				});
			}

			// Adds inline editing to element labels, wraps labels in 'span'
			function _setupItemLabel($item)
			{
				var $label = $item.find('label:first');

                // Do this to support formatting of the label in the FormElement class.
                if ($label.find('.title').length > 0) {
                    $label.html($label.find('.title').html());
                }

				// Wrap labels
				$label.wrapInner('<span class="label_span"></span>');

				// Bind label
				$label.find('span').click(function () {
					var $span = $(this);
					if(!$span.hasClass('editable')) {
						$span.addClass('editable');
						$span.parent().attr('for', '');
						var $input = $('<input class="label_edit" type="text" />').val($span.html());
						// $input.focus().select();
						$input.bind('keypress', function(e) {
							 var code = (e.keyCode ? e.keyCode : e.which);
							 if(code == 13) { //Enter keycode
								 updateItemLabel($item, $input.val());
							 }
						});
						$input.blur(function () {
							updateItemLabel($item, $input.val());
						});
						var $okButton = $('<button type="button" class="label_ok">Ok</button>').button();
						$okButton.live('click', function () {
							updateItemLabel($item, $input.val());
							return false;
						});
						$span.html($input);
						$span.append($okButton);
						$span.find('.label_edit').focus().select();
					}
				});

				return $label;
			}

			function _setupSortable()
			{
				// Make the canvas sortable
				$this.sortable({
					cancel : '.controls, .label_span',
					placeholder: 'item_placeholder ui-state-highlight',
					containment: '#formbuilder .canvas',
					// containment : $('#form_canvas'),
					stop : function (event, ui) {
						var $paletteItem = ui.item;
						// New item added from palette
						if($paletteItem.hasClass('item')) {
							addItem($paletteItem);
						}
						else { // Add item takes care of sorting :D
							sortItems();
						}
					}
				});
			}

			function _setupPalette()
			{
				// Store type for convenience
				$paletteItems.each(function () {
					$(this).data('type', $(this).attr('id').replace('item_', ''));
					$(this).hover(function () {
						$(this).toggleClass('ui-state-highlight');
					});
				});

				// Animate the palette
				$paletteItems.draggable({
					revert: "invalid",
					containment: "#formbuilder",
					helper: "clone",
					cursor: "move",
					connectToSortable: $this
				});
			}

			function _setupSettings()
			{
				var $settingsForm = $('#settings_dialog').find('form');

				// Setup button
				$('#settings_button').button({
					'icons' : {
						'primary' : 'settings_icon'
					}
				}).live('click', function () {
					updateItemOptions();
					$dialogSettings.dialog({
						buttons: [
							{
								text: o.messageClose,
								click: function() { $settingsForm.submit(); }
							}
						]
					}).dialog("open");
				});

				// Setup form
				$settingsForm.find("input[name=settings_confirmation]").live('change', function () {
					var $confirmVal = $('#settings_confirmVal');
					$confirmVal.select().focus();
					if($(this).val() == 'redirect') {
						$confirmVal.css('height', '20px')
					}
					else {
						$confirmVal.removeAttr('style')
					}
				}).filter(':checked').change();

				$settingsForm.find("input[name=settings_submissionEmail]").live('change', function () {
					if($(this).val() == 'default') {
						$('#elm_settings_submissionEmailAddress').show();
						$('#settings_submissionEmailAddressCustom').val('');
						$('#elm_settings_submissionEmailAddressCustom').hide();
					}
					else {
						$('#elm_settings_submissionEmailAddressCustom').show();
						$('#elm_settings_submissionEmailAddress').hide();
					}
				}).filter(':checked').trigger('change');

				$settingsForm.find('#elm_settings_maxSubmissionsOn').find('input').live('change', function() {
					var val = parseInt($(this).val());
					var $inputs = $settingsForm.find('.submissionSel');
					var $parents = $inputs.parents('.elm');
					if (val > 0) {
						$parents.removeClass('hide');
						$inputs.attr('disabled', false);
					}
					else {
						$parents.addClass('hide');
						$inputs.attr('disabled', true);
					}
				}).filter(':checked').trigger('change');

				$settingsForm.ajaxForm({
					beforeSubmit : function (formData, jqForm, options) {
						// Remove any remaining error messages
						$settingsForm.find('.error').remove();
					},
					success : function (responseText, statusText, xhr, $form) {
						if(responseText.success !== true) {
							// Error occurred
							$.each(responseText.messages, function (k, tabs) {
								$.each(tabs, function (elm, messages) {
									$.each(messages, function(i, message) {
										// Show one error message
										var $error = $('<div class="error"></div>');
										$error.html(message);
										var $elm = $form.find('#elm_' + elm);
										$elm.after($error);
										// Switch to next tab
										var tabId = $elm.parents('fieldset').attr('id');
										$elm.parents('.ui-tabs').tabs('select', '#' + tabId);
										return;
									});
									return;
								})
							});
						}
						else {
							$dialogSettings.dialog("close");
							$('.settings dl').replaceWith(responseText.settingsInfo);
							$('.settings dl').effect("highlight", {}, 2000);
							notifyIsModifed();
						}
					}
				});
			}

			function _setupDialogs()
			{
				// Global dialog objects
				$dialog = $('<div></div>').attr('id', 'dialog');
				$dialog.dialog({
					modal : true,
					autoOpen : false,
					height : 530,
					width : 440
				}).hide();

				// Dialog for settings
				$dialogSettings = $('#settings_dialog');
				$dialogSettings.dialog({
					autoOpen : false,
					resizable : false,
					height : 800,
					width : 600,
					modal : true,
					title : 'Stillingar'
				}).hide();

				// Another dialog for confirmation
				$dialogConfirm = $('<div></div>').attr('id', 'dialogConfirm');
				$dialogConfirm.dialog({
					autoOpen : false,
					resizable : false,
					height : 200,
					width : 300,
					modal : true
				}).hide();
			}

			/*******************************
			 * MANIPULATION ITEM FUNCTIONS
			 *******************************/

			// Add an element to the canvas using a palette item as a template
			function addItem($paletteItem)
			{
				disable();
				var type = ($paletteItem.attr('id')) ? $paletteItem.attr('id').replace('item_', '') : $paletteItem.data('type');

				// Waiting ...
				$paletteItem.html('').addClass('item_process');

				// Add a new item
				var params = {design : o.id, type : type};
				$.post(o.urlAddItem, params, function(data) {
					if(data.item) {
						$paletteItem.replaceWith(data.item);
						$item = $this.find('#elm_' + data.itemName);
						_setupItem($item);
						sortItems();
					}
					notifyIsModifed();
				}, 'json');
			}

			// Opens a dialog window with a form and submits via ajax
			function editItem($item)
			{
				var name = $item.data('name');

				$.get(o.urlEditItem, {design : o.id, item : name}, function(data) {
					if(data.form) {
						// Append to form to DOM
						$dialog.html($(data.form));

						// Extract the form as jQuery
						var $form = $dialog.find('form');

						// Use jquery form plugin to send ajax submission
						$form.ajaxForm({
							beforeSubmit : function (formData, jqForm, options) {
								// Remove any remaining error messages
								$form.find('.error').remove();
							},
							success : function (responseText, statusText, xhr, $form) {
								if(responseText.success !== true) {
									// Error occurred
									$.each(responseText.messages, function (elm, messages) {
										$.each(messages, function(i, message) {
											// Show one error message
											var $error = $('<div class="error"></div>');
											$error.html(message);
											$form.find('#' + elm).after($error);
											return;
										});
										return;
									});
								}
								else {
									$item.replaceWith(responseText.item);
									$item = $this.find('#elm_' + name);
									_setupItem($item);
									$dialog.dialog("close");
									$item.effect("highlight", {}, 2000);
									notifyIsModifed();
								}
							}
						});

						// Open the dialog
						$dialog.dialog({
							title : data.title,
							width : data.dialogWidth,
							height : data.dialogHeight,
							buttons: [
								{
									text: o.messageCancel,
									click: function() { $(this).dialog("close"); }
								},
								{
									text: o.messageConfirm,
									click: function() { $(this).find('form').submit(); }
								}
							]
						}).dialog("open");
					}
				}, 'json');
			}

			function isEditableItem($item)
			{
				return true;
				// return ($item.hasClass('elm_pagebreak')) ? false : true;;
			}

			function countItems()
			{
				return $this.find('.elm').length;
			}

			function removeItem($item)
			{
				if(countItems() < 2) {
					alert(o.messageCannotDeleteLastItem);
					return false;
				}

				var name = $item.data('name');
				var title = $item.find('label:first span').html();
				$dialogConfirm
					.html(o.messageRemoveDialogText)
					.dialog({
						title : o.messageRemoveDialogTitle + ": " + title,
						buttons: [
							{
								text: o.messageCancel,
								click: function() { $(this).dialog("close"); }
							},
							{
								text: o.messageConfirm,
								click: function() {
									$.get(o.urlRemoveItem, {design : o.id, item : name}, function () {
										$this.find('#elm_' + name).remove();
										$dialogConfirm.dialog("close");
										sortItems();
									});
								}
							}
						]
				}).dialog('open');

				return true;
			}

			function sortItems()
			{
				// Prepare data
				disable();
				var data = "design=" + o.id + "&" + $this.sortable('serialize', { expression: /(.+)[_](.+)/});
				$.get(o.urlSortItems, data, function (data) {
					enable();
					notifyIsModifed();
				});
			}

			function updateItemLabel($item, value)
			{
				value = value.trim().replace(/<\/?[^>]+(>|$)/g, "");

				if(!value) {
					return false;
				}

				// Replace the value in the DOM
				var $span = $item.find('label:first span');
				$span.html($.fn.formBuilder.stripTags(value));
				$span.removeClass('editable');

				// Notify server of change
				$.get(o.urlUpdateLabel, {design : o.id, item : $item.data('name'), label : value});
				notifyIsModifed();

				return true;
			}

			function updateItemOptions()
			{
				// Prepare data
				$.get(o.urlUpdateItemOptions, {design : o.id}, function (data) {
					$('#elm_settings_emailAddressExtra').replaceWith(data.emailAddressExtraHtml);
					$('#elm_settings_submissionEmailAddress').replaceWith(data.emailAddressHtml);
					$("input[name=settings_submissionEmail]").filter(':checked').trigger('change');
					$('#elm_settings_submissionTag').replaceWith(data.tagHtml);
				});
			}

			/*******************************
			 * MANIPULATION FUNCTIONS
			 *******************************/

			function notifyIsModifed()
			{
				if(!isModified) {
					$('#formbuilder .status_msg').html(o.messageIsModified).parent().effect("highlight", {}, 2000);
					o.isModified.call();
				}

				isModified = true;
			}

			// Disable the widget
			function disable()
			{
				$this.sortable({disabled: true});
				$paletteItems.draggable({disabled:true});
			}

			// Eneable the widget
			function enable()
			{
				$this.sortable({disabled: false});
				$paletteItems.draggable({disabled:false});
			}
		});
	};

	$.fn.formBuilder.stripTags = function(html) {
		return $.trim(html.replace(/<\/?[^>]+>/gi, ''));
	};

	// Default options
	$.fn.formBuilder.defaults = {
		id : '',
		urlAddItem : '',
		urlEditItem : '',
		urlRemoveItem : '',
		urlSortItems : '',
		urlUpdateItemOptions : '',
		urlUpdateLabel : '',
		messageConfirm : 'Confirm',
		messageCancel: 'Cancel',
		messageClose: 'Close',
		messageEditItem : 'editItem',
		messageRemoveItem : 'removeItem',
		messageRemoveDialogText : 'removeDialogText',
		messageRemoveDialogTitle : 'removeDialogTitle',
		messageCannotDeleteLastItem : 'cannotDeleteLastItem',
		messageIsModified : 'isModified',
		isModified : function() {
			needToConfirm = true;
			$('#formbuilder .empty_placeholder').hide();
		}
	}
})(jQuery);