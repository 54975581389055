moya = window.moya || {};
moya.sitemap = moya.sitemap || {};

var moyaSitemapInitEdit = (function () {
	var $form, $linkKey, $linkType, $elms, $linkKeyElms, $parent, $linkKeyElmInputs;
	var itemId, msgs;

	function init() {
		initVars();
		initTypeSelect();
		initDisableParents();
		initSubmitForm();
		initTabs();
		initClearError();
	}

	function initVars() {
		$form = $('#sitemap_item_form');
		$linkKey = $form.find('#linkKey');
		$linkType = $form.find('#linkType');
		$elms = $form.find('.elm');
		$linkKeyElms = $elms.filter('.linkkey');
		$linkKeyElmInputs = $linkKeyElms.find(':input');
		$parent = $form.find('#parent');

		itemId = parseInt($form.data('itemid'));
		msgs = $form.data('msgs');
	}

	function initTypeSelect() {
		$linkKeyElms.hide();
		$linkType.change(typeSelect).trigger('change');
		$linkKeyElmInputs.change(function () {
			$linkKey.val($(this).val());
		});
	}

	function initSubmitForm() {
		$form.submit(doSubmit);
	}

	function initTabs() {
		$form.find('.tabs').tabs();
	}

	function initDisableParents() {
		if (itemId < 1) {
			return;
		}
		var $options = $parent.find('option');
		var filter = '[value=' + itemId + ']';
		var $myOption = $options.filter(filter);
		var myLevel = findOptionLevel($myOption);
		var isFound = false;
		$options.each(function () {
			var value = parseInt(this.value);
			if (value === itemId) {
				isFound = true;
				$(this).attr('disabled', 'disabled');
			} else if (isFound) {
				var $opt = $(this);
				var level = findOptionLevel($opt);
				if (level <= myLevel) {
					return false;
				}
				$opt.attr('disabled', 'disabled');
			}
		});
	}

	function initClearError() {
		var $error = $linkType.parents('div.elm').find('ul.errors');
		if ($error.length > 0) {
			var f = function () {
				$error.hide();
			};
			$linkType.change(f);
			$linkKeyElmInputs.change(f)
		}
	}

	function findOptionLevel($opt) {
		var l = $opt.attr('label');
		if (!l) {
			return 0;
		}
		l = l.replace('/- +[\w\d].*/', '');
		return l.split('-').length;
	}

	function typeSelect(e) {
		var type = e.target;
		var sel = $(type).val();
		$linkKeyElms.hide();
		$linkKeyElms.filter('.elm_Linkkey_' + sel).show();
	}

	function doSubmit() {
		if (!hasGeneralTab()) {
			return true;
		}

		if (!$('#name').val()) {
			return error('Name');
		}
		var type = $linkType.val();
		if (!type) {
			return error('LinkType');
		}
		if (type !== 'firstchild' && !$linkKey.val()) {
			var key = 'LinkKey' + type.charAt(0).toUpperCase() + type.slice(1);
			$form.find('#linkkey_' + type).focus();
			return error(key);
		}
		return true;
	}

	function hasGeneralTab() {
		var key = $form.get(0).id + '-general';
		return $form.contains('#' + key);
	}

	function error(key) {
		key = 'sitemapError' + key;
		var msg = msgs[key] || 'Error';
		moya.message.growl(msg, 'error');
		return false;
	}

	return {
		init: init
	}
})();

moya.sitemap.initEdit = function () {
	moyaSitemapInitEdit.init();
};

