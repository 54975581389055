moya.search = window.moya.search || {};

moya.search = {
	initAutocomplete: function (selector) {
		if (!site) {
			site = {};
		}
		if (!site.search) {
			site.search = {};
		}
		if (!site.search.autocomplete) {
			site.search.autocomplete = {};
		}
		var $element = $(selector);
		if ($element.length) {
			var options = {
				source: function (request, response) {
					$.ajax({
						url: '/_/' + moya.division + '/search/ajax/autocomplete',
						dataType: 'json',
						data: {
							q: encodeURIComponent(request.term),
						},
						success: function (data) {
							response(data);
						},
					});
				},
				minLength: 3,
				select: moya.search.autocompleteSelect,
				focus: moya.search.autocompleteFocus,
				close: moya.search.autocompleteClose,
			};
			if (site.search.autocomplete.options) {
				options = $.extend({}, options, site.search.autocomplete.options);
			}
			$element.autocomplete(options).autocomplete('instance')._renderItem = moya.search.autocompleteRender;
		}
	},
	/*
	 * Regarding the following methods, we can overwrite them through the site.search.autocomplete object.
	 * If a function returns false, we exit early.
	 * So to continue the core code, return true from site.
	 */
	autocompleteSelect: function (event, ui) {
		if (site.search.autocomplete.autocompleteSelect) {
			if (!site.search.autocomplete.autocompleteSelect(event, ui)) {
				return false;
			}
		}
		if (ui.item && ui.item.value.url) {
			window.location = ui.item.value.url;
		}

		return false;
	},
	autocompleteFocus: function (event, ui) {
		if (site.search.autocomplete.autocompleteFocus) {
			if (!site.search.autocomplete.autocompleteFocus(event, ui)) {
				return false;
			}
		}
		// Do nothing
		return false;
	},
	autocompleteClose: function (event, ui) {
		if (site.search.autocomplete.autocompleteClose) {
			if (!site.search.autocomplete.autocompleteFocus(event, ui)) {
				return false;
			}
		}
		// Do nothing
		return false;
	},
	autocompleteRender: function (ul, item) {
		// Here we always exit if we find a site function
		if (site.search.autocomplete.autocompleteRender) {
			return site.search.autocomplete.autocompleteRender(ul, item);
		}
		return $('<li>')
			.append('<span class="result__type">' + item.value.type + '</span>')
			.append('<span class="result__label">' + item.label + '</span>')
			.appendTo(ul);
	},
};
