function MoyaAutocomplete(form) {
	/** @var HTMLFormElement form */
	this.form = form;
	this.input = form.querySelector('input[type=text]');
	if (!this.input) {
		return;
	}
	this.timeoutHandler = null;
	this.abortController = null;
	this.previousValue = '';
	this.toggle = null;
	this.findOptions();
	this.prepareHtml();
}

MoyaAutocomplete.init = function () {
	const autocompleteForms = document.querySelectorAll('.search__autocomplete');
	if (autocompleteForms.length < 1) {
		return;
	}
	autocompleteForms.forEach(function (form) {
		new MoyaAutocomplete(form).run();
	});
};

MoyaAutocomplete.prototype.prepareHtml = function () {
	let resultWrap = this.form.querySelector('.results');
	if (!resultWrap) {
		resultWrap = document.createElement('div');
		resultWrap.classList.add('results');
		this.input.insertAdjacentElement('afterend', resultWrap);
	}
	let wrap = this.form.querySelector('.wrap');
	if (!wrap) {
		wrap = document.createElement('div');
		wrap.classList.add('wrap');
		resultWrap.appendChild(wrap);
	}
	let header = this.form.querySelector('.header');
	if (!header) {
		header = document.createElement('div');
		header.classList.add('header');
		wrap.appendChild(header);
	}
	let ul = wrap.querySelector('ul');
	if (!ul) {
		ul = document.createElement('ul');
		ul.setAttribute('role', 'listbox');
		wrap.appendChild(ul);
	}
	this.resultWrap = resultWrap;
	this.resultHeader = header;
	this.resultList = ul;
};

MoyaAutocomplete.prototype.initOptions = function () {
	this.minLength = 3;
	this.delay = 400;
	this.url = '/_/' + moya.division + '/search/ajax/autocomplete';
	this.fetchOpts = {};
	this.cssClassWaiting = 'search__wait';
	this.cssClassEmpty = 'search__empty';
	this.shouldAddType = true;
};

MoyaAutocomplete.prototype.findOptions = function () {
	this.initOptions();
	if (this.form.dataset['min_len']) {
		this.minLength = parseInt(this.form.dataset['min_len']);
	}
	if (this.form.dataset['delay']) {
		this.delay = parseInt(this.form.dataset['delay']);
	}
	if (this.form.dataset['url']) {
		this.url = this.form.dataset['url'];
	}
	if (this.form.dataset['add_type']) {
		this.shouldAddType = parseInt(this.form.dataset['add_type']) > 0;
	}
};

MoyaAutocomplete.prototype.abort = function () {
	clearTimeout(this.timeoutHandler);
	if (this.abortController) {
		this.abortController.abort();
		this.abortController = null;
	}
};

MoyaAutocomplete.prototype.run = function () {
	if (!this.input) {
		return;
	}
	if (this.input instanceof HTMLInputElement) {
		this.input.autocomplete = "off";
		this.input.setAttribute('aria-autocomplete', 'both');
	}
	this.input.addEventListener('keyup', (evt) => {
		this.handleKey(evt);
	});
	this.input.addEventListener('keydown', (evt) => {
		if (this.handleSpecialKey(evt)) {
			return false;
		}
	});
	this.initToggle();
};

MoyaAutocomplete.prototype.handleKey = function (evt) {
	let value = this.input.value;
	const keyCode = evt.which || evt.keyCode || 0;
	// 229 is a strange one. Needs to be here though. Just try to google it :)
	if (keyCode === 13 || keyCode === 38 || keyCode === 40 || keyCode === 229) {
		return;
	}
	if (!this.inputHasChanged()) {
		return;
	}
	this.abort();
	if (value.length < this.minLength) {
		this.clear();
		this.toggle.close();
		return;
	}
	this.timeoutHandler = setTimeout(() => {
		this.search();
	}, this.delay);
};

MoyaAutocomplete.prototype.inputHasChanged = function() {
	const prev = this.previousValue.trim();
	const current = this.input.value.trim();
	this.previousValue = this.input.value;
	return current !== prev;
};

MoyaAutocomplete.prototype.handleSpecialKey = function (evt) {
	const keyCode = evt.which || evt.keyCode || 0;
	switch (keyCode) {
		case 40: // ArrowDown
			this.moveActive(1);
			return true;
		case 38: // ArrowUp
			this.moveActive(-1);
			return true;
		case 13: // Enter
			evt.preventDefault();
			evt.stopPropagation();
			const hasActive = this.gotoActiveLink();
			if (hasActive) {
				return hasActive;
			}
			return this.gotoAllResults();
		default:
			break;
	}
	return false;
};

MoyaAutocomplete.prototype.findActiveResult = function () {
	return Array.from(this.resultList.children).filter((child) => child.classList.contains('active')).shift();
};

MoyaAutocomplete.prototype.gotoActiveLink = function () {
	const current = this.findActiveResult();
	if (!current) {
		return false;
	}
	const a = current.querySelector('a');
	if (!a) {
		return false;
	}

	a.dispatchEvent(new MouseEvent('click'));
	return true;
};

MoyaAutocomplete.prototype.gotoAllResults = function () {
	this.form.submit();
	return true;
};

MoyaAutocomplete.prototype.moveActive = function (step) {
	let current = this.resultList.querySelector('.active');
	if (!current) {
		current = step > 0 ? this.resultList.firstChild : this.resultList.lastChild;
		if (current) {
			current.classList.add('active');
		}
		return;
	}
	current.classList.remove('active');
	let goto = step > 0 ? current.nextSibling : current.previousSibling;
	if (!goto) {
		goto = step > 0 ? this.resultList.firstChild : this.resultList.lastChild;
	}
	if (!goto || goto === current) {
		return;
	}

	goto.classList.add('active');
};
MoyaAutocomplete.prototype.prepareFetch = function () {
	if (!this.abortController) {
		this.abortController = new AbortController();
		this.fetchOpts['signal'] = this.abortController.signal;
	}
};

/** @return URL */
MoyaAutocomplete.prototype.createFetchUrl = function () {
	const value = this.input.value;
	const url = new URL(this.url, window.location.origin);
	const params = {
		'header': '1'
	};
	params[this.input.name] = value;

	url.search = new URLSearchParams(params).toString();
	return url;
};

MoyaAutocomplete.prototype.search = function () {
	const url = this.createFetchUrl();
	this.form.classList.add(this.cssClassWaiting);
	this.prepareFetch();
	fetch(url, this.fetchOpts)
		.then(response => response.json())
		.then(data => {
			if (data.hasOwnProperty('error')) {
				this.error(data.error);
				return;
			}
			this.results(data);
		})
		.catch(err => {
			if (err.name === 'AbortError') {
				return;
			}
			this.error(err);
		})
		.finally(() => {
			this.form.classList.remove(this.cssClassWaiting);
		})
	;
};

MoyaAutocomplete.prototype.showResult = function (data) {
	this.resultList.appendChild(this.createResultItem(data));
};

MoyaAutocomplete.prototype.showResultHeader = function (data) {
	this.resultHeader.appendChild(this.createResultHeader(data));
};

MoyaAutocomplete.prototype.createResultHeader = function (data) {
	const label = document.createElement('div');
	label.classList.add('result__label');

	const title = document.createElement('span');
	title.classList.add('result__title');
	title.textContent = data.label;

	const all = document.createElement('a');
	all.href = this.createAllUrl();
	all.classList.add('result__seeAll');
	all.innerHTML = data.value;

	label.appendChild(title);
	label.appendChild(all);
	return label;
};

MoyaAutocomplete.prototype.createAllUrl = function() {
	const url = new URL(this.form.action, window.location.origin);
	const params = {};
	params[this.input.name] = this.input.value + '*';
	url.search = new URLSearchParams(params).toString();
	return url.toString();
};

MoyaAutocomplete.prototype.createResultItem = function (data) {
	const li = document.createElement('li');
	li.classList.add('result');
	li.setAttribute('role', 'option');
	li.appendChild(this.createResultItemLink(data));
	if (data.value.tags) {
		data.value.tags.forEach(function (tag) {
			li.classList.add('tag-' + moya.slugify(tag));
		});
	}
	if (data.value.types) {
		data.value.types.forEach(function (type) {
			li.classList.add('type-' + type);
		});
	}
	li.addEventListener('mouseover', () => {
		li.classList.add('active');
	});
	li.addEventListener('mouseout', () => {
		li.classList.remove('active');
	});
	this.postResultItem(li);
	return li;
};

MoyaAutocomplete.prototype.createResultItemLink = function (data) {
	const a = document.createElement('a');
	a.href = data.value.url;
	this.createResultItemContent(data, a);
	return a;
};

MoyaAutocomplete.prototype.createResultItemContent = function (data, linkElement) {
	if (this.shouldAddType) {
		this.createResultItemType(data, linkElement);
	}
	this.createResultItemLabel(data, linkElement);
	this.postItemContent(linkElement);
};

MoyaAutocomplete.prototype.createResultItemType = function (data, linkElement) {
	const span = document.createElement('span');
	span.setAttribute('data-type', data.value.type);
	span.classList.add('result__type');
	linkElement.appendChild(span);
};

MoyaAutocomplete.prototype.createResultItemLabel = function (data, linkElement) {
	const label = document.createElement('span');
	label.classList.add('result__label');
	const title = document.createElement('span');
	title.classList.add('result__title');
	title.innerHTML = data.label;
	label.appendChild(title);
	linkElement.appendChild(label);
};

MoyaAutocomplete.prototype.empty = function () {
	this.form.classList.add(this.cssClassEmpty);
	this.resultHeader.innerHTML = '<p>No result</p>';
	this.resultList.innerHTML = '';
	this.toggle.close();
};

MoyaAutocomplete.prototype.error = function (err) {
	console.error(err);
	this.resultHeader.innerHTML = '<p>Error: ' + err + '</p>';
	this.resultList.innerHTML = '';
};

MoyaAutocomplete.prototype.clear = function () {
	this.form.classList.remove(this.cssClassEmpty);
	this.resultHeader.innerHTML = this.resultList.innerHTML = '';
};

MoyaAutocomplete.prototype.results = function (data) {
	this.clear();
	if (data.length < 1) {
		this.empty();
		return;
	}
	data.forEach(item => {
		if (item.header) {
			this.showResultHeader(item);
			return;
		}
		this.showResult(item);
	});
	this.toggle.open();
	this.postResults();
};

let numToggles = 0;

MoyaAutocomplete.prototype.initToggle = function() {
	const toggle = MoyaContainer.get('core.toggle');
	let toggleKey = 'searchResults';
	if (numToggles > 0) {
		toggleKey += numToggles;
	}
	this.toggle = toggle.create(
		this.input,
		this.resultWrap,
		toggleKey,
		{
			triggerClickEvent: false,
			onClose: () => this.clear(),
		}
	);
	numToggles++;
};

MoyaAutocomplete.prototype.postResults = function () {};

MoyaAutocomplete.prototype.postResultItem = function (liElement) {};
MoyaAutocomplete.prototype.postItemContent = function (linkElement) {};

document.addEventListener('DOMContentLoaded', function() {
	MoyaAutocomplete.init();
});

